import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner'
import ContactForm from '../components/contact-form/contact-form';
import ImageBackground from '../images/contactBackground.webp';

class Contact extends React.Component {
    render() {
        const getInTouchTextBanner = get(this, 'props.data.getInTouchTextBanner');
        return (
            <Layout location={this.props.location}>
                <Seo title="Get In Touch" />
                
                    <div className="container-fluid container-fluid">
                        <TextBanner textOne={getInTouchTextBanner.textOne} textTwo={getInTouchTextBanner.textTwo} />
                        <ContactForm heading1Text="Directly" heading2Text="Or fill out our form" email="enquiries@ecomplete.com" addressLine1="PROFOLK" addressLine2="Bank Chambers" addressLine3="Stockport" addressLine4="SK1 1AR" formName="Name" formEmail="email" formCompany="Company" formTelephone="Telephone" formMessage="Message" buttonText="Submit" image={ImageBackground} />
                    </div>
            </Layout>
        )
    }
}

export default Contact;

export const query = graphql`
query contactQuery {
    getInTouchTextBanner: contentfulTextBanner(textBannerName: {eq: "getInTouchTextBanner"}) {
        textBannerName
        textOne
        textTwo
    }
}
`
